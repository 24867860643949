import React from "react";
import sanitizeHtml from "sanitize-html";

const defaultOptions: sanitizeHtml.IOptions = {
  allowedTags: [
    "b",
    "i",
    "em",
    "strong",
    "a",
    "br",
    "p",
    "ul",
    "li",
    "ol",
    "span",
    "h1",
    "h2",
    "h3",
    "div",
  ],
  allowedAttributes: {
    a: ["href"],
  },
};

const sanitize = (dirty: string, options: sanitizeHtml.IOptions) => ({
  __html: sanitizeHtml(dirty, { ...defaultOptions, ...options }),
});

interface ISanitize {
  html: string;
  options?: sanitizeHtml.IOptions;
}

export const SanitizeHTML = ({ html, options }: ISanitize) => (
  <div dangerouslySetInnerHTML={sanitize(html, options || {})} />
);
