import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { ITheme } from "styles/themes/types";
import { ICommonProps } from "components/shared";
import Pill from "components/Pill/Pill";
import { IWorkItem } from "services/azure";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import Icon from "components/Icon/Icon";
import { MaistroHTML } from "../MaistroHTML/html";

const useStyles = createUseStyles((theme: ITheme) => ({
  section: {
    ...theme.typography.content.project,
    padding: 0,
    "&>header": {
      display: "flex",
      gridGap: theme.spacing.medium,
      alignItems: "center",
    },
  },
  sectionTitle: {
    ...theme.typography.headings.h2,
    color: theme.colors.primary,
  },
  title: {
    ...theme.typography.headings.h3,
    color: theme.colors.primary,
    fontWeight: "bold",
  },
  itemList: {
    marginTop: theme.spacing.medium,
    padding: 0,
    listStyle: "none",
    margin: 0,
    display: "flex",
    flexDirection: "column",
    gridGap: theme.spacing.small,

    "& > li": {
      padding: theme.spacing.medium,
      backgroundColor: theme.colors.white,
      borderRadius: theme.spacing.large,
      border: `1px solid ${theme.colors.grey}`,
    },
    "& > li > header": {
      display: "flex",
      alignItems: "center",
      gridGap: theme.spacing.medium,
    },
  },
  releaseNote: {
    paddingTop: theme.spacing.medium,
  },
}));

interface ISectionProps extends ICommonProps {
  title: string;
  icon: IconDefinition;
  workItems: IWorkItem[];
  hideTitle?: boolean;
}

const Section: React.FC<ISectionProps> = (props) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ ...props, theme });

  return (
    <section className={classes.section}>
      <header>
        <Icon size='large' icon={props.icon} />
        <h2 className={classes.sectionTitle}>{props.title}</h2>
      </header>
      <ul className={classes.itemList}>
        {props.workItems.map((item) => (
          <li key={item.id}>
            <header>
              <Pill
                value={`No. ${item.id}`}
                size='small'
                background={theme.colors.status.completed}
              />
              <h3 className={classes.title}>
                {!props.hideTitle && item.title}
              </h3>
            </header>
            <div className={classes.releaseNote}>
              <MaistroHTML html={item.releaseNote} />
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

Section.defaultProps = {} as ISectionProps;

export default Section;
