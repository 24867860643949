import React from "react";
import MainContainer from "./containers/main-container";
import { ThemeProvider } from "react-jss";
import maistroTheme from "styles/themes/maistro";

function App() {
  return (
    <ThemeProvider theme={maistroTheme}>
      <MainContainer />
    </ThemeProvider>
  );
}

export default App;
