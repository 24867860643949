import React, { useEffect, useState } from "react";
import { getAllReleases, IQuery } from "../services/azure";
import BaseLayout from "../components/Layout/BaseLayout";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";

const MainContainer: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [releases, setReleases] = useState<IQuery[]>([]);
  const [currentRelease, setCurrentRelease] = useState<IQuery>();

  useEffect(() => {
    console.log("Fetching API data...");

    getAllReleases()
      .then((allReleases) => {
        setReleases(allReleases);
        setIsLoading(false);
        const validReleases = allReleases.filter((r) => r.isValid);
        validReleases && setCurrentRelease(validReleases[0]);
      })
      .catch((e) => console.log(e));
  }, []);

  return isLoading ? (
    <div>
      <LoadingScreen />
    </div>
  ) : (
    <div>
      <BaseLayout
        releases={releases}
        errorText={
          (releases.filter((r) => !r.isValid).length > 0 &&
            "Invalid release name: " +
              releases.filter((r) => !r.isValid)[0].queryName) ||
          undefined
        }
        currentRelease={currentRelease}
        onChangeRelease={(r) => setCurrentRelease(r)}
      />
    </div>
  );
};
export default MainContainer;
