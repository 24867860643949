import React from "react";
import { createUseStyles, useTheme } from "react-jss";

import { ITheme } from "styles/themes/types";
import { ICommonProps } from "components/shared";
import Loading from "components/Loading/Loading";

export interface ILoadingScreenProps extends ICommonProps {
  titleKey: string;
  messageKey: string;
  duration: number;
}

const useStyles = createUseStyles((theme: ITheme) => ({
  loadingWrapper: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 100,
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  content: {
    color: theme.colors.white,
  },
}));

const LoadingScreen: React.FC = () => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ theme });

  return (
    <div className={classes.loadingWrapper}>
      <div className={classes.content}>
        <Loading size='medium' text='Loading data...' />
      </div>
    </div>
  );
};

export default LoadingScreen;
