import React from "react";
import { SanitizeHTML } from "components/Sanitize/sanitize-html";
import { createUseStyles, useTheme } from "react-jss";
import { ITheme } from "styles/themes/types";

interface IHTML {
  html: string;
}

const useStyles = createUseStyles((theme: ITheme) => ({
  root: {
    "& ul": {
      listStyle: "initial",
      marginTop: theme.spacing.xSmall,
      marginBottom: theme.spacing.xSmall,
    },
    "& li": {
      marginLeft: theme.spacing.large,
      padding: theme.spacing.xSmall,
    },
    "& p": {
      marginTop: theme.spacing.xSmall,
      marginBottom: theme.spacing.xSmall,
    },
  },
}));

export const MaistroHTML = ({ html }: IHTML) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ theme });
  return (
    <div className={classes.root}>
      <SanitizeHTML html={html} />
    </div>
  );
};
