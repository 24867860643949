import React, { ReactNode } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { ITheme } from "styles/themes/types";
import logo from "assets/images/maistro/logo.png";
import {
  faBugSlash,
  faLifeRing,
  faQuestionCircle,
  faRocket,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import Icon from "components/Icon/Icon";
import MaistroSelect from "components/MaistroSelect/maistro-select";
import Section from "components/Section/section";
import { IQuery } from "services/azure";
import classNames from "classnames";
import { isInternal } from "services/environment";

const ERROR_BAR_HEIGHT = 40;
const HEADER_HEIGHT = 100;
const HEADER_HEIGHT_MOBILE = 150;

const useStyles = createUseStyles((theme: ITheme) => ({
  layout: {
    ...theme.typography.base,

    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    backgroundColor: theme.colors.lightGrey,
  },

  header: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing.large,
    position: "fixed",
    width: "100%",
    minHeight: HEADER_HEIGHT_MOBILE,
  },

  brand: {
    display: "flex",
    alignItems: "center",
    "& h1": {
      ...theme.typography.headings.h1,
      color: theme.colors.white,
    },
    width: "100%",
  },

  nav: {
    display: "flex",
    gridGap: theme.spacing.medium,
    width: "100%",
  },

  logo: {
    maxHeight: 24,
    marginBottom: 5,
    marginRight: theme.spacing.large,
  },

  error: {
    position: "fixed",
    top: HEADER_HEIGHT_MOBILE,
    width: "100%",
    height: ERROR_BAR_HEIGHT,
    backgroundColor: theme.colors.status.error,
    color: theme.colors.white,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing.medium,
  },

  content: {
    padding: theme.spacing.large,
    backgroundColor: theme.colors.lightGrey,
    display: "flex",
    flexDirection: "column",
    gridGap: theme.spacing.large,
    "& > *": {
      width: "100%",
    },
  },

  title: {
    marginTop: HEADER_HEIGHT_MOBILE,

    backgroundColor: theme.colors.white,
    padding: theme.spacing.medium,
    ...theme.typography.content.project,
    listStyle: "none",
    display: "grid",
    gridGap: theme.spacing.xSmall,
    "& label": {
      display: "inline-block",
      fontWeight: "bold",
      minWidth: 120,
    },
  },
  titleErrorText: {
    marginTop: ERROR_BAR_HEIGHT + HEADER_HEIGHT_MOBILE,
  },

  help: {
    cursor: "pointer",
    "&:hover": {
      color: theme.colors.hover,
    },
  },

  stars: {
    display: "flex",
  },

  star: {
    color: theme.colors.stars,
  },

  hideMobile: {
    display: "none",
  },

  "@media (min-width: 650px)": {
    header: {
      flexDirection: "row",
      minHeight: HEADER_HEIGHT,
    },
    nav: {
      maxWidth: 400,
    },
    title: {
      marginTop: HEADER_HEIGHT,
    },

    titleErrorText: {
      marginTop: ERROR_BAR_HEIGHT + HEADER_HEIGHT,
    },
    error: {
      top: HEADER_HEIGHT,
    },
  },
  "@media (min-width: 900px)": {
    hideMobile: {
      display: "inline-block",
      paddingLeft: 4,
    },
  },
}));

interface ILayoutProps {
  children?: ReactNode;
  releases: IQuery[];
  currentRelease: IQuery | undefined;
  onChangeRelease: (release: IQuery) => void;
  errorText?: string;
}

const diffDays = (date1: Date | undefined, date2: Date | undefined) => {
  if (!date1 || !date2) return 0;
  const diff = Math.abs(date1.getTime() - date2.getTime());
  return Math.ceil(diff / (1000 * 3600 * 24));
};

const BaseLayout: React.FC<ILayoutProps> = (props) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ theme, ...props });
  const showErrorBar = props.errorText && isInternal();
  const recentReleases = props.releases.filter((r) => r.isValid).slice(0, 10);
  const releaseFrequency =
    diffDays(
      recentReleases[0].date,
      recentReleases[recentReleases.length - 1].date
    ) / recentReleases.length;

  return (
    <div
      data-component='BaseLayout'
      data-testid='base-layout'
      className={classes.layout}
    >
      <header className={classes.header}>
        <div className={classes.brand}>
          <img className={classes.logo} src={logo} alt='Maistro Logo' />
          <h1>
            Platform Releases
            <span className={classes.hideMobile}>
              {isInternal() && " - Internal Notes"}
            </span>
          </h1>
        </div>
        <div className={classes.nav}>
          <MaistroSelect
            name='releases'
            defaultValue={props.currentRelease?.id}
            options={props.releases.map((r) => ({
              value: r.id,
              label: r.date
                ? r.date.toLocaleDateString("en-GB") + " - " + r.name
                : r.name,
            }))}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              props.onChangeRelease(
                props.releases.find((r) => r.id === e.target.value) ||
                  props.releases[0]
              )
            }
          />
          <a
            href='https://resources.maistro.com/hubfs/Platform%20Guides/platform_guide_combined.pdf'
            target='_blank'
            rel='noreferrer'
          >
            <Icon
              className={classes.help}
              icon={faQuestionCircle}
              size='medium'
              alt='View the platform user guide'
            />
          </a>
          {isInternal() && (
            <div
              className={classes.stars}
              title={`Release Frequency: ${releaseFrequency} days`}
            >
              <Icon
                className={releaseFrequency < 365 / 2 ? classes.star : ""}
                icon={faStar}
                size='medium'
              />
              <Icon
                className={releaseFrequency < 30 ? classes.star : ""}
                icon={faStar}
                size='medium'
              />
              <Icon
                className={releaseFrequency < 7 ? classes.star : ""}
                icon={faStar}
                size='medium'
              />
            </div>
          )}
        </div>
      </header>
      {showErrorBar && (
        <div className={classes.error}>
          <p>{props.errorText}</p>
        </div>
      )}
      <ul
        className={classNames(
          classes.title,
          showErrorBar && classes.titleErrorText
        )}
      >
        {props.currentRelease && (
          <li>
            <label>Release Name</label>
            {props.currentRelease.name}
          </li>
        )}
        {props.currentRelease && (
          <li>
            <label>Release Date</label>
            {props.currentRelease.date
              ? props.currentRelease.date.toLocaleDateString("en-GB")
              : "Unknown"}
          </li>
        )}
        {!props.currentRelease && <p>Please choose a release to display</p>}
      </ul>
      {props.currentRelease && (
        <div className={classes.content}>
          {props.currentRelease.issues.length > 0 && (
            <Section
              title="What's New"
              hideTitle={!isInternal()}
              icon={faRocket}
              workItems={props.currentRelease.issues}
            />
          )}
          {props.currentRelease.bugs.length > 0 && (
            <Section
              title='Bug Fixes'
              hideTitle={!isInternal()}
              icon={faBugSlash}
              workItems={props.currentRelease.bugs}
            />
          )}
          {props.currentRelease.supportTickets.length > 0 && (
            <Section
              title='Support Issues'
              hideTitle={!isInternal()}
              icon={faLifeRing}
              workItems={props.currentRelease.supportTickets}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default BaseLayout;
