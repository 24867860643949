/* eslint-disable @typescript-eslint/no-explicit-any */
declare const window: any;
let env: any;

if (typeof(process.env) !== "undefined" && process.env.NODE_ENV === "development") {
  env = process.env;
} else {
  env = window.env;
}

export function isInternal() {
  if (env.REACT_APP_VISIBILITY === "external") return false;
  if (env.REACT_APP_VISIBILITY === "internal") return true;
  throw new Error("Error reading visibility status");
}

export function PersonalAccessToken() {
  const token: string = env.REACT_APP_PERSONAL_ACCESS_TOKEN || "";
  const pattern = /\w{52}/;
  if (!pattern.test(token))
    throw new Error(
      "Invalid PAT or PAT not found: " + env.REACT_APP_PERSONAL_ACCESS_TOKEN
    );
  return token;
}
