import base64 from "base-64";
import { isInternal, PersonalAccessToken } from "./environment";

interface IReleaseQueryName {
  name: string;
  date: Date | undefined;
}

interface IResponse {
  count: number;
  value: IQueryResponse[];
}

interface IQueryResponse {
  id: string;
  name: string;
  date: Date;
  url: string;
  _links: { wiql: { href: string } };
}

interface IQueryDetailResponse {
  workItems: IQueryWorkItemResponse[];
}
interface IQueryWorkItemResponse {
  id: number;
  url: string;
}

interface IWorkItemResponse {
  id: number;
  fields: {
    "System.WorkItemType": "Issue" | "Bug";
    "System.State": string;
    "System.Title": string;
    "Custom.ReleaseNote": string;
    "Custom.ExternalReleaseNote": string;
    "System.Tags": string;
  };
}

export interface IQuery {
  id: string;
  isValid: boolean;
  queryName: string;
  name: string;
  url: string;
  date: Date | undefined;
  workItemLink: string;
  issues: IWorkItem[];
  bugs: IWorkItem[];
  supportTickets: IWorkItem[];
  workItemCount: number;
}

export interface IWorkItem {
  id: number;
  tags: string;
  workItemType:
    | "Issue"
    | "Bug"
    | "Support"
    | "Standard"
    | "Fixed Date"
    | "Expedited";
  title: string;
  releaseNote: string;
}

const apiVersion = "7.1-preview.2";
const organisation = "maistro";
const baseUrl = `https://dev.azure.com/${organisation}/platform/_apis/wit/queries?api-version=${apiVersion}&$filter=Release&$depth=2`;

const releaseNamingRegEx1 = /Release - [ \w]* - 202\d-[01]\d-[0-3]\d/;
const releaseNamingRegEx2 = /Release - 202\d-[01]\d-[0-3]\d - [ \w]*/;

const isValidQueryName = (queryTitle: string): boolean => {
  return (
    releaseNamingRegEx1.test(queryTitle) || releaseNamingRegEx2.test(queryTitle)
  );
};

const getReleaseName = (queryTitle: string): IReleaseQueryName | undefined => {
  const parts = queryTitle.split(" - ");
  if (releaseNamingRegEx1.test(queryTitle))
    return {
      name: parts[1],
      date: new Date(parts[2]),
    };
  if (releaseNamingRegEx2.test(queryTitle))
    return {
      name: parts[2],
      date: new Date(parts[1]),
    };
  if (parts[1])
    return {
      name: parts[1],
      date: undefined,
    };
  return {
    name: "Unknown",
    date: undefined,
  };
};

const headers = {
  Authorization: "Basic " + base64.encode(":" + PersonalAccessToken()),
};

const getAzureLink = async <T>(url: string) => {
  const response = await fetch(url, {
    headers,
  });
  return (await response.json()) as T;
};

const getWorkItems = async (url: string) => {
  const workItemQueryResponse: IQueryDetailResponse =
    await getAzureLink<IQueryDetailResponse>(url);
  const allItems = await Promise.all(
    workItemQueryResponse.workItems.map(async (i) => {
      const workItemResponse: IWorkItemResponse =
        await getAzureLink<IWorkItemResponse>(i.url);
      return {
        id: i.id,
        tags: workItemResponse.fields["System.Tags"],
        workItemType: workItemResponse.fields["System.WorkItemType"],
        title: workItemResponse.fields["System.Title"],
        releaseNote:
          workItemResponse.fields[
            isInternal() ? "Custom.ReleaseNote" : "Custom.ExternalReleaseNote"
          ],
      };
    })
  );
  return allItems.filter((i) => i.releaseNote);
};

const sortByDateDesc = (a: IQuery, b: IQuery) => {
  if (b.date && a.date)
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  return 0;
};

export const getAllReleases = async () => {
  const response = await getAzureLink<IResponse>(baseUrl);

  const items: IQuery[] = await Promise.all(
    response.value.map(async ({ id, name, url, _links }) => {
      const releaseDetails = getReleaseName(name);
      const workItems: IWorkItem[] = await getWorkItems(_links.wiql.href);
      return {
        id,
        queryName: name,
        isValid: isValidQueryName(name),
        name: releaseDetails ? releaseDetails.name : "Invalid release query",
        date: releaseDetails ? releaseDetails.date : undefined,
        url,
        workItemLink: _links.wiql.href,
        workItemCount: workItems.length,
        bugs: workItems.filter((i) => i.workItemType === "Bug"),
        issues: workItems.filter(
          (i) =>
            i.workItemType === "Issue" ||
            i.workItemType === "Standard" ||
            i.workItemType === "Expedited" ||
            i.workItemType === "Fixed Date"
        ),
        supportTickets: workItems.filter((i) => i.workItemType === "Support"),
      };
    })
  );

  //put invalid releases at the top

  const dateIsInThePast = (date: Date | undefined) => {
    if (date) return date.getTime() < new Date().getTime();
    return false;
  };

  const releasesWithItems = items.filter((r) => r.workItemCount > 0);

  const validReleases = releasesWithItems
    .filter((r) => r.isValid)
    .filter((r) => isInternal() || dateIsInThePast(r.date)) // external releases are only shown if in the past
    .sort(sortByDateDesc);

  const invalidReleases = releasesWithItems
    .filter((r) => !r.isValid)
    .sort(sortByDateDesc);

  return isInternal() ? [...invalidReleases, ...validReleases] : validReleases; // hide invalid releases from external
};
