import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import loadingImage from "assets/images/loading.gif";
import classNames from "classnames";
import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
  small: {
    height: 16,
  },

  medium: {
    height: 22,
  },

  large: {
    height: 35,
  },

  loading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: theme.spacing.unit,
  },

  loadingText: {
    ...theme.typography.headings.h3,
    color: "inherit",
  },
}));

interface ILoadingProps extends ICommonProps {
  size: "small" | "medium" | "large";
  text?: string;
}

const Loading: React.FC<ILoadingProps> = (props) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ theme });

  return (
    <div
      className={classNames(classes.loading, props.className)}
      data-component='Loading'
      data-testid={props.testid}
    >
      <img
        className={classNames({ [classes[props.size]]: props.size })}
        src={loadingImage}
        alt='Loading...'
      />
      {props.text && <div className={classes.loadingText}>{props.text}</div>}
    </div>
  );
};

export default Loading;
